import React from "react";
import { Tag, Button, Avatar, DatePicker, Space, Input, Select } from "antd";
import { message as messageApi, property as propertyApi } from "../../apis";
import {
  PageContainer,
  PageHeader,
  PageContent,
} from "../../components/Layout";
import { MyTable, RowAction } from "../../components";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Filter } from "../../components/Design";
import usePermission from "../../hooks/usePermission";
import moment from "moment";
import styled from "styled-components";
import { dateFormat, renderUser } from "../../utils";
import { useDispatch } from "react-redux";
import { useTranslate } from "../../hooks";
import { UserCircleIcon } from "@heroicons/react/24/solid";
import { SearchOutlined } from "@ant-design/icons";
import useFetch from "../../hooks/useFetch";

export default () => {
  const { translate } = useTranslate();
  const history = useHistory();
  const myTableRef = React.useRef();
  const params = useParams();
  const [query, setQuery] = React.useState("");
  const { s3host, roles, isMobile, orderStatuses } = useSelector((state) => state.general);
  const { checkPermission } = usePermission();
  const dispatch = useDispatch();
  const [properties] = useFetch(propertyApi.selectAdmin)([]);
  const [ownerProperties] = useFetch(propertyApi.selectOwner)([]);

  const [filters, setFilters] = React.useState({
    startDate : "",
    endDate   : "",
    query     : "",
    propertyId: undefined,
    status    : "",
  });

  const renderStatus = (status) => {
    if (status === "NEW")
      return (
        <Tag color="blue">
          {translate(`system.orderstatus.${status}`, status)}
        </Tag>
      );
    if (status === "PAID")
      return (
        <Tag color="green">
          {translate(`system.orderstatus.${status}`, status)}
        </Tag>
      );
    if (status === "CHAT")
      return (
        <Tag color="blue">
          {translate(`system.orderstatus.${status}`, status)}
        </Tag>
      );
    if (status === "PENDING")
      return (
        <Tag color="orange">
          {translate(`system.orderstatus.${status}`, status)}
        </Tag>
      );
    if (status === "TRANSFER")
      return (
        <Tag color="blue">
          {translate(`system.orderstatus.${status}`, status)}
        </Tag>
      );
    if (status === "CANCELLED")
      return (
        <Tag color="red">
          {translate(`system.orderstatus.${status}`, status)}
        </Tag>
      );
  };

  const columns = useHeader({
    translate,
    s3host,
    roles,
    renderStatus,
    type    : params.type,
    checkPermission,
    onAction: async (key, record) => {
      switch (key) {
        case "detail": {
          history.push(`/conversation/${record._id}`);
          break;
        }
        default:
      }
    },
  });
  let timeout;
  React.useEffect(() => {
    if (timeout) clearTimeout(timeout);

    if (filters.query !== query)
      timeout = setTimeout(() => {
        setFilters((state) => ({ ...state, query: query }));
      }, 300);

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [query]);

  React.useEffect(() => {
    setFilters({
      startDate : undefined,
      query     : "",
      endDate   : undefined,
      propertyId: undefined,
      status    : undefined,
    });
  }, [params.type]);

  const dateFilter = (e) => {
    setFilters({
      ...filters,
      startDate: e && moment(e[0]).startOf("days").toDate(),
      endDate  : e && moment(e[1]).endOf("days").toDate(),
    });
  };

  const onClear = () => {
    setQuery("");
    setFilters({
      startDate : undefined,
      query     : "",
      endDate   : undefined,
      propertyId: undefined,
      status    : undefined,
    });
  };

  const {
    data,
    isValidating,
    mutate,
  } = async () => {
    try {
      const res = await messageApi.list(filters.type, {
        offset: {
          page : filters.page,
          limit: filters.limit,
        },
        filter: {
          propertyId: filters.propertyId,
          status    : filters.status,
        },
      });
      dispatch({
        type   : "socket.conversations",
        payload: [...res.rows],
      });

      return res;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  return (
    <PageContainer>
      <PageHeader
        title={translate("message.list.pageheader.title", "Conversation")}
      />
      <PageContent>
        <Filter
          extra={[
            <DatePicker.RangePicker
              value={[
                filters.startDate && moment(filters.startDate),
                filters.endDate && moment(filters.endDate),
              ]}
              onChange={dateFilter}
            />,
            <Button onClick={onClear}>
              {translate("system.filter.btn.clear", "Clear")}
            </Button>,
          ]}
        >
          <Select
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            style={{ width: 300 }}
            value={filters.propertyId}
            onChange={(e) => setFilters({ ...filters, propertyId: e })}
            placeholder={translate("income.list.select.property", "Property ")}
            allowClear
            options={(ownerProperties.concat(properties)).map((item) => {
              return {
                value: item._id,
                label: item.name,
              };
            })}
          ></Select>
          <Input
            className="grid-item"
            prefix={<SearchOutlined />}
            value={query}
            placeholder={translate(
              "system.input.search.orderid",
              "Search by order id..."
            )}
            onChange={(e) => setQuery(e.target.value)}
            allowClear
          />
          <Select
            className="grid-item"
            value={filters.status}
            style={{ width: "130px" }}
            onChange={(e) => setFilters({ ...filters, status: e })}
            placeholder={translate("order.list.filter.status", "Order status")}
            allowClear
          >
            {orderStatuses.map((type, index) => (
              <Select.Option key={index} value={type}>
                {translate(`system.orderstatus.${type}`, type)}
              </Select.Option>
            ))}
          </Select>
        </Filter>
        <Table
          ref={myTableRef}
          columns={columns}
          filters={filters}
          loadData={messageApi.history}
          rowKey={(record) => record?.rowKey}
        />
      </PageContent>
    </PageContainer>
  );
};

const useHeader = ({ onAction, translate, renderStatus }) => {
  return [
    {
      title    : "№",
      dataIndex: "rowKey",
      width    : "20px",
    },
    {
      title : translate("system.useheader.title.owner", "Owner"),
      render: (row) => {
        return renderUser(row.owner);
      },
    },
    {
      title : translate("system.useheader.title.tenant", "Tenant"),
      render: (row) => {
        return renderUser(row.tenant);
      },
    },
    {
      title : translate("system.useheader.list.property", "Property"),
      render: (row) => {
        return (
          <Space>
            <Avatar size={45} shape="square" src={row?.property?.image} />
            <div>
              <b style={{}}>{row?.property?.name}</b> <br />
            </div>
          </Space>
        );
      },
    },
    {
      title : translate("system.useheader.title.orderid", "Order ID"),
      render: (record) => {
        return <Tag>{record?.order?.orderId}</Tag>;
      },
    },
    {
      title : translate("system.useheader.list.order.status", "Order status"),
      render: (record) => {
        return renderStatus(record.orderStatus);
      },
    },
    {
      title : translate("order.list.useheader.dates", "Dates"),
      render: (record) => {
        return (
          <Tag>{`${dateFormat(record.startDate)}-${dateFormat(
            record.endDate
          )}`}</Tag>
        );
      },
    },
    // {
    //   title : translate("system.useheader.title.date", "Date"),
    //   render: (record) => {
    //     return (
    //       <div>{moment(record?.createdAt).format("YYYY/MM/DD HH:mm")}</div>
    //     );
    //   },
    // },
    {
      title : translate("system.useheader.title.action", "Action"),
      width : 100,
      render: (record) => {
        return (
          <Button onClick={() => onAction("detail", record)}>
            {translate("system.list.action.detail", "Detail")}
          </Button>
        );
      },
    },
  ];
};

const Table = styled(MyTable)`
  .ant-table-cell {
    padding: 8px;
    line-height: 15px;
  }
`;
