import { Line } from "@ant-design/plots";
import { Flex, Paper, Text } from "@mantine/core";
import { Button, Col, DatePicker, Divider, Input, message, Row, Select, Spin } from "antd";
import moment from "moment";
import React from "react";
import useSWR from "swr";
import { dashboard } from "../../apis";
import { useTranslate } from "../../hooks";
import TableChart from "./Table";
import styled from "@emotion/styled";
import { MyTable } from "../../components";
import { Filter } from "../../components/Design";
import { PageContent } from "../../components/Layout";
import { SearchOutlined } from "@ant-design/icons";
const { RangePicker } = DatePicker;

const LineChart = () => {
  const { translate } = useTranslate();
  const tableRef = React.useRef();
  const nowMonth = moment().format("MMMM");
  const [query, setQuery] = React.useState("");
  const [filters, setFilters] = React.useState({
    query    : "",
    startDate: "",
    endDate  : "",
  });

  const columns = useHeader({
    translate,
  });
  const dateFilter = (e) => {
    setFilters({
      ...filters,
      startDate: e && moment(e[0]).startOf("days").toDate(),
      endDate  : e && moment(e[1]).endOf("days").toDate(),
    });
  };

  let timeout;

  React.useEffect(() => {
    if (timeout) clearTimeout(timeout);

    if (filters.query !== query)
      timeout = setTimeout(() => {
        setFilters((state) => ({ ...state, query: query }));
      }, 300);

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [query]);


  return (
    <PageContent>
      <Filter >
        <Input
          className="grid-item"
          prefix={<SearchOutlined />}
          value={query}
          placeholder={translate("system.input.search", "Search...")}
          onChange={(e) => setQuery(e.target.value)}
          allowClear
        />
        <DatePicker.RangePicker
          value={[
            filters.startDate && moment(filters.startDate),
            filters.endDate && moment(filters.endDate),
          ]}
          onChange={dateFilter}
        />
        <Button >
          {translate("system.filter.btn.clear", "Clear")}
        </Button>
      </Filter>
      <Table
        ref={tableRef}
        columns={columns}
        filters={filters}
        loadData={dashboard.dashboard}
        rowKey={(record) => record?.rowKey}
      />
    </PageContent>
  );
};
export default LineChart;

const useHeader = ({ translate }) => {
  return [
    {
      title    : "№",
      dataIndex: "rowKey",
      width    : "20px",
    },
    {
      title : "クエリ検索",
      width : "50px",
      render: (record) => {
        return (
          <div style={{ minWidth: 250, maxWidth: 500 }}>
            <p>{record.value}</p>
          </div>
        );
      },
    },
    {
      title : "カウント",
      render: (record) => {
        return (
          <div style={{ maxWidth: 150 }}>
            <p>{record.count}</p>
          </div>
        );
      },
    },
  ];
};

const Table = styled(MyTable)`
  .ant-table-cell {
    padding: 8px;
    line-height: 15px;
  }
`;
