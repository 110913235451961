import React from "react";
import { Badge, Menu } from "antd";
import { NavLink, useHistory } from "react-router-dom";
import {
  SettingOutlined,
  SolutionOutlined,
  MessageOutlined,
  ContainerOutlined,
  CommentOutlined,
  CustomerServiceOutlined,
  ProfileOutlined,
  UserOutlined,
  PayCircleOutlined,
  CreditCardOutlined,
  FileProtectOutlined,
  PieChartOutlined,
} from "@ant-design/icons";
import styled from "styled-components";
import { useTranslate } from "../../hooks";
import { useSelector } from "react-redux";
import usePermission from "../../hooks/usePermission";

const { SubMenu } = Menu;

export default ({ isMobile }) => {
  const { translate } = useTranslate();
  const { notifications, orders, tickets, payments } = useSelector((state) => state.general);
  const { checkPermission } = usePermission();
  const history = useHistory();
  let selected = history.location.pathname || "";

  return (
    <Container>
      <Menu
        defaultSelectedKeys={[selected]}
        mode="inline"
        theme="light"
        inlineCollapsed={isMobile}
      >
        {checkPermission("SPACE") && <Menu.Item key="/dashboard" icon={<PieChartOutlined />}>
          {/* {translate("navbar.menu.dashboard", "Dashboard")} */}
          Dashboard
          <NavLink to="/dashboard" />
        </Menu.Item>}
        {checkPermission("SPACE") && <Menu.Item key="/property" icon={<ProfileOutlined />}>
          {translate("navbar.menu.properties", "Properties")}
          <NavLink to="/property" />
        </Menu.Item>}
        {checkPermission("SPACE_REQUEST") && <SubMenu
          key={["/property/request", "/user"]}
          title={translate("navbar.menu.property.request", "Property request")}
          icon={<SettingOutlined />}
        >
          <Menu.Item key="/property/createrequest" icon={<ContainerOutlined />}>
            {translate("navbar.menu.property.createrequest", "Create request")}
            <NavLink to="/property/request/create" />
          </Menu.Item>
          <Menu.Item key="/property/update_request" icon={<ContainerOutlined />}>
            {translate("navbar.menu.property.updaterequest", "Update request")}
            <NavLink to="/property/request/update" />
          </Menu.Item>
        </SubMenu>}
        {checkPermission("ORDER") && <Menu.Item key="/order" icon={<SolutionOutlined />}>
          <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }} >
            <div>{translate("navbar.menu.order", "Order")} <NavLink to="/order" /></div>
            {orders > 0 && <Badge overflowCount={99} count={orders} color="#fa3232"> </Badge>}
            <div/>
          </div>
        </Menu.Item>}
        {checkPermission("PAYMENT") && <Menu.Item key="/transaction" icon={<CreditCardOutlined />}>
          <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }} >
            <div>  {translate("navbar.menu.transaction", "Transaction")} <NavLink to="/transaction" /></div>
            {payments > 0 && <Badge overflowCount={99} count={payments} color="#fa3232"> </Badge>}
            <div/>
          </div>
        </Menu.Item>}
        {checkPermission("CHAT") && <Menu.Item key="/chat" icon={<CommentOutlined />}>
          <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }} >
            <div> {translate("navbar.menu.chat", "Chat")} <NavLink to="/chat" /></div>
            {notifications > 0 && <Badge overflowCount={99} count={notifications} color="#fa3232"> </Badge>}
            <div/>
          </div>
        </Menu.Item>}
        {checkPermission("CONVERSATION") && <Menu.Item key="/conversation" icon={<MessageOutlined />}>
          {translate("navbar.menu.conversation", "Conversation")}
          <NavLink to="/conversation" />
        </Menu.Item>}
        <hr />
        {checkPermission("APP_USER") && <Menu.Item key="/app_user" icon={<UserOutlined />}>
          {translate("navbar.menu.app.user", "App user")}
          <NavLink to="/app_user" />
        </Menu.Item>}
        {checkPermission("TICKET") && <Menu.Item key="/ticket" icon={<CustomerServiceOutlined />}>
          <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }} >
            <div>{translate("navbar.menu.ticket", "Ticket")}<NavLink to="/ticket" /></div>
            {tickets > 0 && <Badge overflowCount={99} count={tickets} color="#fa3232"> </Badge>}
            <div/>
          </div>
        </Menu.Item>}
        {checkPermission("INCOME") && <SubMenu
          key={["/income/menu", "/user"]}
          title={translate("navbar.menu.income", "Income")}
          icon={<PayCircleOutlined />}
        >
          <Menu.Item key="/income" icon={<PayCircleOutlined />}>
            {translate("navbar.menu.income", "Income")} <NavLink to="/income" />
          </Menu.Item>
          {checkPermission("OWNER_INCOME") && <Menu.Item key={["/income", "/owner"]} icon={<PayCircleOutlined />}>
            {translate("navbar.menu.incomeowner", "Owner income")} <NavLink to="/income/owner" />
          </Menu.Item>}
          {/* <Menu.Item key="/income/exports" icon={<ContainerOutlined />}>
            {translate("navbar.menu.exports", "Exports")}
            <NavLink to="/income/exports" />
          </Menu.Item> */}
        </SubMenu>}
        {checkPermission("RECOMMEND") && <Menu.Item key="/recommend" icon={<FileProtectOutlined />}>
          {translate("navbar.menu.recommedation.list", "Recommedation list")}
          <NavLink to="/recommend" />
        </Menu.Item>}
        {checkPermission("SETTING") &&<SubMenu
          key={["/settings", "/user"]}
          title={translate("navbar.menu.settings", "Settings")}
          icon={<SettingOutlined />}
        >
          <Menu.Item key="/settings/news">
            {translate("navbar.submenu.news", "News")}
            <NavLink to="/settings/news" />
          </Menu.Item>
          <Menu.Item key="/settings/article">
            {translate("navbar.submenu.article", "Article")}
            <NavLink to="/settings/article" />
          </Menu.Item>
          <Menu.Item key="/user/admin">
            {translate("navbar.submenu.admin.user", "Admin user")}
            <NavLink to="/user/admin" />
          </Menu.Item>
          <Menu.Item key="/settings/role">
            {translate("navbar.submenu.user.role", "User role")}
            <NavLink to="/settings/role" />
          </Menu.Item>
          <Menu.Item key="/settings/equipments">
            {translate("navbar.submenu.equipments", "Equipments")}
            <NavLink to="/settings/equipments" />
          </Menu.Item>
          <Menu.Item key="/settings/types">
            {translate("navbar.submenu.user.types", "Use types")}
            <NavLink to="/settings/types" />
          </Menu.Item>
          <Menu.Item key="/settings/property_types">
            {translate("navbar.submenu.property.types", "Property types")}
            <NavLink to="/settings/property_types" />
          </Menu.Item>
          <Menu.Item key="/settings/policy">
            {translate("navbar.submenu.cancel.policy", "Cancel policy")}
            <NavLink to="/settings/policy" />
          </Menu.Item>
          <Menu.Item key="/settings/location">
            {translate("navbar.submenu.location.settings", "Location settings")}
            <NavLink to="/settings/location" />
          </Menu.Item>
          <Menu.Item key="/settings/localization">
            {translate("navbar.submenu.localization", "Localization")}
            <NavLink to="/settings/localization" />
          </Menu.Item>
          <Menu.Item key="/settings/documents">
            {translate("navbar.submenu.documents", "Documents")}
            <NavLink to="/settings/documents" />
          </Menu.Item>
          <Menu.Item key="/settings/calendar">
            {translate("navbar.submenu.calendar", "Calendar")}
            <NavLink to="/settings/calendar" />
          </Menu.Item>
        </SubMenu>}
      </Menu>
    </Container>
  );
};

const Container = styled.div`
  z-index: 1000;
  padding-top: 20px;
  .project {
    padding: 20px;
    width: 100%;
    &.mobile {
      .ant-btn-group {
        .ant-btn:first-child {
          display: none !important;
        }
      }
    }
    .ant-btn-group {
      width: 100%;
      .ant-btn:last-child {
        width: 39px !important;
      }
      .ant-btn:first-child {
        width: 100%;
      }
    }
  }
  .ant-menu {
    border-right: none;

    .ant-menu-item {
      font-size: 14px;
      height: 30px;
      line-height: 30px;
      color: #333;
      font-weight: 400;
      &.ant-menu-item-selected {
        background-color: transparent;
        color: #1890ff;
      }
    }
  }
`;
