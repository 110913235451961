/* eslint-disable key-spacing */
import React from "react";
import {
  Tag,
  Button,
  Avatar,
  Input,
  DatePicker,
  Select,
  Divider,
  Pagination,
  Spin,
} from "antd";
import {
  dashboard,
} from "../../apis";
import { SearchOutlined } from "@ant-design/icons";
import {
  PageContent,
} from "../../components/Layout";
import { MyTable, } from "../../components";
import { useHistory, useParams } from "react-router-dom";
import { Filter } from "../../components/Design";
import { PictureOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import usePermission from "../../hooks/usePermission";
import styled from "styled-components";
import { useTranslate } from "../../hooks";
import moment from "moment";
import useSWR from "swr";
import { Paper, Flex, Text } from "@mantine/core";
import { Bar } from "@ant-design/plots";

export default () => {
  const myTableRef = React.useRef();
  const [query, setQuery] = React.useState("");
  const { s3host, user } = useSelector(
    (state) => state.general
  );
  const { checkPermission } = usePermission();
  const { translate } = useTranslate();
  const [currentPage, setCurrentPage] = React.useState(1);
  const pageSize = 10;

  const [filters, setFilters] = React.useState({
    query: "",
    startDate: "",
    endDate: "",
    status: undefined,
  });

  const columns = useHeader({
    s3host,
    user,
    translate,
    checkPermission,
  });

  let timeout;

  React.useEffect(() => {
    if (timeout) clearTimeout(timeout);

    if (filters.query !== query)
      timeout = setTimeout(() => {
        setFilters((state) => ({ ...state, query: query }));
      }, 300);

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [query]);

  const dateFilter = (e) => {
    setFilters({
      ...filters,
      startDate: e && moment(e[0]).startOf("days").toDate(),
      endDate: e && moment(e[1]).endOf("days").toDate(),
    });
  };

  const onClear = () => {
    setQuery("");
    setFilters({
      query: "",
      startDate: undefined,
      endDate: undefined,
    });
  };
  const { data: data } = useSWR(`dashboard.list.${JSON.stringify(filters)}`, async () => {
    try {
      const res = await dashboard.impression({ filter: filters,
        offset: {
          page : 1,
          limit: 100,
        }
      });
      return res.rows;
    } catch (err) {
      console.log("error", err);
      throw err;
    }
  });

  const allData = data?.map((item) => ({ year: item?.name, value: item?.seenCount }));

  const getCurrentPageData = () => {
    if (!allData) return [];
    const startIndex = (currentPage - 1) * pageSize;
    return allData.slice(startIndex, startIndex + pageSize);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const config = {
    data  : getCurrentPageData(),
    xField: "value",
    yField: "year",
    sort  : {
      reverse: true,
    },
    label: {
      position: "right",
      style   : {
        fill: "#000",
      },
    },
    xAxis: {
      label: {
        autoRotate  : true,
        autoHide    : true,
        autoEllipsis: true,
      },
    },
  };
  if (!data) {
    return <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", height: "300px" }}>
      <Spin />
    </div>;
  }
  return (
    <PageContent>
      <Filter >
        <Input
          className="grid-item"
          prefix={<SearchOutlined />}
          value={query}
          placeholder={translate("system.input.search", "Search...")}
          onChange={(e) => setQuery(e.target.value)}
          allowClear
        />
        <DatePicker.RangePicker
          value={[
            filters.startDate && moment(filters.startDate),
            filters.endDate && moment(filters.endDate),
          ]}
          onChange={dateFilter}
        />
        <Select
          className="grid-item"
          value={filters.status}
          style={{ width: "130px" }}
          onChange={(e) => setFilters({ ...filters, status: e })}
          placeholder={translate("property.list.select.status", "Status")}
          allowClear
        >
          {["DRAFT", "ACTIVE", "DISACTIVE"].map((item, index) => (
            <Select.Option key={index} value={item}>
              {translate(item, item)}
            </Select.Option>
          ))}
        </Select>
        <Button onClick={onClear}>
          {translate("system.filter.btn.clear", "Clear")}
        </Button>
      </Filter>
      <Flex justify="space-between" w="100%" bg="gray.2" style={{ borderRadius: "4px" }} p={10} mb={10}>
        <Text size="xl" fw={700}>
          {translate("dashboard.barchart.title", "Property")}
        </Text>
      </Flex>
      <Paper withBorder p={15}>
        <Bar {...config} key={currentPage} />
        <Pagination
          current={currentPage}
          total={allData.length}
          pageSize={pageSize}
          onChange={handlePageChange}
          style={{ marginTop: 16, textAlign: "center" }}
        />
      </Paper>
      <Divider />
      <Table
        ref={myTableRef}
        columns={columns}
        filters={filters}
        loadData={dashboard.impression}
        rowKey={(record) => record?.rowKey}
      />
    </PageContent>
  );
};

const useHeader = ({ translate }) => {
  return [
    {
      title: "№",
      dataIndex: "rowKey",
      width: "20px",
    },
    {
      title: translate("system.property.list.image", "Image"),
      width: "50px",
      render: (record) => {
        return (
          <Avatar
            src={`${record.image}`}
            shape="square"
            icon={<PictureOutlined />}
          />
        );
      },
    },
    {
      title: translate("dashboard.prop.space.name", "Name"),
      render: (record) => {
        return (
          <div style={{ minWidth: 150, maxWidth:250 }}>
            <p>{record.name}</p>
          </div>
        );
      },
    },
    {
      title: translate("dashboard.prop.title.status", "Status"),
      render: (record) => {
        return (
          <Tag>{translate(record.propertyStatus, record.propertyStatus)}</Tag>
        );
      },
    },
    {
      title: translate("dashboard.prop.title.count", "Count"),
      render: (record) => {
        return (
          <Tag color="blue">{record.seenCount}</Tag>
        );
      },
    },
  ];
};

const Table = styled(MyTable)`
  .ant-table-cell {
    padding: 8px;
    line-height: 15px;
  }
`;
