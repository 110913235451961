
import React from "react";
import { Form, Input, Checkbox, FormItem } from "formik-antd";
import { Formik } from "formik";
import { Button, Card, notification } from "antd";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { login } from "../../apis/auth";
import LogoImage from "../../assets/logo-square.svg";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useTranslate } from "../../hooks";
import { useHistory } from "react-router-dom";

const Login = () => {
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const { env } = useSelector((state) => state.general);
  const { remember } = useSelector((state) => state.auth);
  const history = useHistory();

  const openNotificationWithIcon = (data) => {
    notification[data.type]({
      message    : data.message,
      description: "",
    });
  };

  const FormSchema = Yup.object().shape({
    username: Yup.string()
      .typeError(translate("system.regex.required", "required field"))
      .required(
        translate("login.required.username", "Enter your user name")
      ),
    password: Yup.string()
      .typeError(translate("system.regex.required", "required field"))
      .required(
        translate("login.required.password", "Enter your password")
      ),
  });

  const onSubmit = async (data, actions, translate) => {
    try {
      const res = await login(data);
      if (res) {
        dispatch({
          type   : "auth.remember",
          payload: {
            username: data.remember
              ? data?.username?.toLowerCase().trim()
              : null,
            remember: data.remember && data.remember,
          },
        });
        dispatch({
          type   : "auth.change",
          payload: res,
        });
      }
      history.push("/dashboard");

      actions.setSubmitting(false);
    } catch (err) {
      openNotificationWithIcon({
        type   : "error",
        message: translate(
          "login.message.error",
          "The user name or password is incorrect."
        ),
      });
      actions.setSubmitting(false);
    }
  };

  return (
    <Container>
      <Card className="login">
        <div className="logo">
          <img src={LogoImage} />
          <h2>
            {translate("login.kariteya", "Kariteya")}
            <p>{translate("login.admin.dashboard", "Admin dashboard")}</p>
          </h2>
        </div>
        <Formik
          initialValues={{
            username: undefined,
            password: undefined,
            ...remember,
            remember: !!remember?.remember,
          }}
          validationSchema={FormSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => (
            <Form layout={"vertical"}>
              <FormItem
                name="username"
                label={translate("login.username", "Username")}
              >
                <Input
                  prefix={<UserOutlined />}
                  name="username"
                  placeholder={translate("login.username", "Username")}
                />
              </FormItem>
              <FormItem
                name="password"
                label={translate("login.password", "Password")}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  name="password"
                  placeholder={translate("login.password", "Password")}
                  type="password"
                  className="form-input"
                />
              </FormItem>
              {/* <div className="forgot">
                <a href="#">
                  {translate("login.forgot.pass", "Forgot password!")}
                </a>
              </div> */}
              <div className="remember">
                <Checkbox name="remember">
                  {translate("login.remember.me", "Remember me")}
                </Checkbox>
              </div>
              <Button
                htmlType="submit"
                type="primary"
                loading={isSubmitting}
                block
              >
                {translate("login.signin", "Log in")}
              </Button>
            </Form>
          )}
        </Formik>
      </Card>
    </Container>
  );
};

const Container = styled.div`
  background: #f8f8f8;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  justify-content: center;
  display: flex;
  flex-direction: center;
  align-items: center;
  .logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
    h2 {
      margin: 0;
      p {
        font-size: 12px;
        margin: 0;
        font-weight: 500;
      }
    }
    img {
      height: 60px;
      margin-right: 10px;
    }
  }

  .login {
    background: #fff;
    border: 1px solid #e8e8e8;
    width: 320px;
    margin: auto;
    padding-bottom: 10px;
    .alert {
      font-size: 12px;
      padding: 10px 10px 10px 35px;
      margin-bottom: 20px;
    }
    .forgot {
      text-align: right;
      margin-top: -20px;
    }
    .remember {
      margin-bottom: 10px;
      margin-top: 20px;
    }
  }
`;

export default Login;
