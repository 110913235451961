import { Stack } from "@mantine/core";
import React from "react";
import { PageContainer, PageContent, PageHeader } from "../../components/Layout";
import { useTranslate } from "../../hooks";
import LineChart from "./Line";
import PropertyTable from "./List";

const Dashboard = () => {
  const { translate } = useTranslate();

  return (
    <PageContainer>
      <PageHeader title={translate("dashboard.pageheader.title", "Dashboard")} />
      <PageContent>
        <Stack gap={0} w="100%">
          <LineChart />
          <PropertyTable/>
        </Stack>
      </PageContent>
    </PageContainer>
  );
};

export default Dashboard;